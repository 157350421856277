import React from 'react';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import Sidebar from 'components/admin/sidebaritem';

const SidebarPage = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
  
        <div>
          <Sidebar />
        </div>
    </>
  );
};

export default SidebarPage;
